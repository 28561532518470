@import url("https://fonts.googleapis.com/css?family=Crimson+Text");

html,
body {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Crimson Text", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

button {
  font-family: "Crimson Text", serif;
}
